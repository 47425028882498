import { graphql } from 'gatsby'
import React from 'react'

import Breadcrumb from '../components/breadcrumb'
import Heading from '../components/heading'
import Image from '../components/image'
import Layout from '../components/layout'
import Link from '../components/link'
import List from '../components/list'
import ListItem from '../components/list-item'
import Paragraph from '../components/paragraph'
import SEO from '../components/seo'


export const frontmatter = {
  body: 'Note Families',
  slug: '/notes/',
  title: 'Note Families',
}

export const query = graphql`
  query NotesPageQuery {
    ...SiteInfo
    imageMain: file(relativePath: { eq: "notes.jpg" }) { ...ImageMain }
    results: postgres {
      families: allFamiliesList(orderBy: SORT_ASC) {
        ...Family
      }
    }
  }
`

export default ({ data }) => {
  const { families } = data.results
  const { links, meta } = data.site.siteMetadata
  const { entity } = meta
  const imageMain = data.imageMain.childImageSharp.fixed

  const page = 'Note Families'
  const title = `${entity.singular} ${page}`
  const description = `
    Fragrance Note Families are groups of scents which are placed together
    based on similarities in smell. Nearby Families share more aspects than
    those far apart. We have organized Notes into four main Families: Fresh,
    Floral, Oriental, and Woods. Fresh fragrances are known to be youthful,
    Floral scents are commonly described as feminine, Oriental tones tend to
    be appreciated as mature and dignified, and the Woods family seems quite
    masculine. Much like wine aspects, notes are metaphorical descriptions of
    the scents found in a fragrance, they are not necessarily suggestions of
    actual ingredients.
  `
  const keywords = title.split(/\W+/)
  const trail = [
    {
      name: page,
      slug: links.notes,
    },
  ]
  const schema = {
    breadcrumb: trail,
    description,
    image: imageMain.src,
    keywords,
    name: title,
    things: families,
    slug: links.notes,
  }

  return (
    <Layout>
      <SEO
        description={description}
        keywords={keywords}
        schema={schema}
        title={title}
      />
      <Breadcrumb trail={trail} />

      <Heading>{title}</Heading>

      <Image
        alt={title}
        credit="notes.jpg"
        fixed={imageMain}
      />

      <Paragraph>
        {description}
      </Paragraph>

      <List>
        {families.map(family => (
          <ListItem key={family.id}>
            <Link href={family.slug}>
              {family.name} Family
            </Link>
          </ListItem>
        ))}
      </List>
      <Breadcrumb trail={trail} />
    </Layout>
  )
}
